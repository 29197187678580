
import { createSlice } from "@reduxjs/toolkit";

const examSlice = createSlice({
  name: "examlist",
  initialState: {
    exams: [], // Initialize as an empty array
  },
  reducers: {
    setExamData(state, action) {
      state.exams = action.payload;
    },
  },
});

export const { setExamData } = examSlice.actions;
export default examSlice.reducer;
